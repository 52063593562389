import React from "react";

function Project(props) {
  return (
    <div className="card" style={{height: "100%"}}>
      <div className="card-header">
        <a href={props.url} target="blank">
          <h3 className="card-header-title">{props.title}</h3>
        </a>
      </div>
      <div className="card-image">
        <figure className="image">
          <img width="200" height="420" src={props.image} alt="" />
        </figure>
      </div>
      <div className="card-content">
        {props.url &&
        (<a href={props.url} target="blank">
          View Project
        </a>)
        }
        <div className="content">
          <p>{props.extract}</p>
        </div>
      </div>
    </div>
  );
}

export default Project;
