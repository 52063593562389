import React from "react";
import Resume from "../../resume.json";

function AboutMe() {
  return (
    <section className="section" id="aboutMe">
      <div className="container has-text-centered">
        <figure className="image container is-180x180">
          <img
            width="180px"
            height="180px"
            src={Resume.basics.picture}
            alt={Resume.basics.name}
            className="is-rounded"
            onError={(e)=>{e.target.onerror = null; e.target.src=Resume.basics.x_pictureFallback}}
          />
        </figure>
        <p style={{fontSize: 27, marginBottom: 0}}>
          Tom Stowe
        </p>
        <p style={{marginTop:0, marginBottom: 0, color:"grey"}}>Senior Software Engineer at Avco Systems</p>
        <p style={{marginTop:0, marginBottom: 40, color:"grey"}}>Buckinghamshire, UK</p>
        <p className="subtitle is-6 has-text-weight-light summary-text">
          {Resume.basics.summary}
        </p>
        <p className="subtitle is-6 has-text-weight-light summary-text">
          {Resume.basics.summaryLine2}
        </p>
        <p className="subtitle is-6 has-text-weight-light summary-text">
          {Resume.basics.summaryLine3}
        </p>
      </div>
      <div className="hero-foot" style={{ marginTop: 30, paddingBottom: "20px" }}>
        <div className="columns is-mobile">
          <div className="column"></div>
          {Resume.basics.profiles.map((value, index) => {
            return (
              <div key={index} className="column has-text-centered">
                <a
                  href={value.url}
                  target="blank"
                  className="is-hovered"
                  title={value.network}
                >
                  <span className="icon is-medium is-">
                    <i className={value.x_icon}></i>
                  </span>
                </a>
              </div>
            );
          })}
          <div className="column"></div>
        </div>
      </div>
    </section>
  );
}

export default AboutMe;
