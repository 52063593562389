import React, { Component } from "react";
import Project from "../elements/Project";
import Resume from "../../resume.json";

class Projects extends Component {  
  render() {

    

    
    return (
      <section className="section" id="projects">
        <div className="container">
          <h1 className="title">Featured Projects</h1>
          <div className="columns">
            {
            Resume.projects.map((project, index) => {
              return (
                <div className="column" key={index}>
                  <Project
                    key={index*10}
                    title={project.title}
                    url={project.link}
                    image={project.icon}
                    extract={project.description}
                  />
                </div>
              );
              })
            }
          </div>
        </div>
      </section>
    );
  }
}

export default Projects;
