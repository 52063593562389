import React from "react";
import TimelineItem from "./TimelineItem";
import Resume from "../../resume.json";

function Timeline() {
  let processedYears = [];

  const getYearFromWork = (work) => {
    // Get the current year if the end date is current
    if (work.endDate === "Current"){
      return new Date().getFullYear();
    }
    return new Date(work.endDate !== "" ? work.endDate : work.startDate).getFullYear();
  };

  // Format the display of the current date
  const formatCurrentDate = (work) => {
    let startDate = work.startDate !== "" ?
      new Date(work.startDate).toLocaleString("en-UK", {
        month: "long",
        year: "numeric"
      }) :
      "";

    let endDate = work.endDate !== "" ?
      (work.endDate !== "Current" ? 
        new Date(work.endDate).toLocaleString("en-UK", {
          month: "long",
          year: "numeric"
        }) :
        "Current")
      : "";

    return startDate + (startDate !== "" && endDate !== "" ? " - " : "") + endDate;
  }

  return (
    <div className="timeline is-centered">
      <header className="timeline-header">
        <span className="tag is-medium is-dark">
          {new Date().getFullYear()}
        </span>
      </header>
      <div className="timeline-item">
        <div className="timeline-marker is-info"></div>
        <div className="timeline-content"></div>
      </div>
      {Resume.work
        .map(item => {
          processedYears = [];
          return getYearFromWork(item);
        })
        .filter((year) => {
          if (processedYears.includes(year)){
            return false;
          }
          processedYears.push(year);
          return true;
        })
        .map((year, i) => {
          let content = [];
          content.push(
            <header key={i} className="timeline-header">
              <span className="tag is-info">{year}</span>
            </header>
          );
          content.push(
            Resume.work
              .filter(work => getYearFromWork(work) === year)
              .map((item, j) => {
                return (
                  <TimelineItem
                    key={j}
                    date={formatCurrentDate(item)}
                    company={item.company}
                    summary={item.summary}
                    icon={item.icon}
                  />
                );
              })
          );
          return content;
        })}
    </div>
  );
}

export default Timeline;
